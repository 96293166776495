import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout.js"
import Seo from "../components/seo"
import { Row, BlogRow } from "../components/row/Row.js"
import { ReturnLink } from "../components/link/Link"
import { BlogTitle, SubHeading, Subtitle, Heading } from "../components/title/Title"
import { TextMedium, TextSmall } from "../components/text/Text"
import { Section, AboveFold } from "../components/section/Section"
import { Column50 } from "../components/column/Column"
import { Breadcrumb } from "../components/breadcrumb/Breadcrumb"
import { Author } from "../components/author/Author"
import Subscribe from "../components/subscribe/Subscribe"
import BlogBody from "../components/blogBody/BlogBody"
import get from 'lodash/get'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { faTwitter, faFacebook, faMedium, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import imgAuth from "../static/authors/andreeamacoveiciuc.jpeg"
import {ImageHome} from "../components/image/Image.js"


library.add(
    faMedium,
    faLinkedin,
    faFacebook,
    faTwitter
);



export const pageQuery = graphql`
  query ContentfulCaseStudy($slug: String!) {
    contentfulCaseStudy(slug: { eq: $slug }) {
      id
      title
      description {
        childMarkdownRemark {
          timeToRead
        }
      }
      excerpt
      slug
      date(formatString: "Do MMMM, YYYY")
      content {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            id
            title
            url
            file {
              url
            }
          }
          gatsbyImageData(width: 400)
        }
      }
      author {
        author
      }
      featuredImage{
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

class CaseStudyTemplate extends React.Component {
  render() {
    const post = this.props.data.contentfulCaseStudy
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const subtitle=this.props.data.contentfulCaseStudy.subtitle
    const caseTitle = this.props.data.contentfulCaseStudy.title
    const caseContent = this.props.data.contentfulCaseStudy.content
    const excerpt = this.props.data.contentfulCaseStudy.excerpt
    const slug = this.props.data.contentfulCaseStudy.slug
    const authorName = this.props.data.contentfulCaseStudy.author.author
    const image = this.props.data.contentfulCaseStudy.featuredImage.resize.src 

    const Bold = ({ children }) => <b>{children}</b>
    const Italic = ({ children }) => <i>{children}</i>

    const options = {
      renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
      },
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
          return <TextMedium textMedium={children} />
        },
        [BLOCKS.HEADING_2]: (node, children) => {
          return <Subtitle subtitle={children} />
        },
        [BLOCKS.HEADING_3]: (node, children) => {
          return <Heading heading={children} />
        },
        [BLOCKS.EMBEDDED_ASSET]: node => {
          const url = node.data.target.file.url
          const alt = node.data.target.title
          return <img alt={alt} src={url} />
        },
        [INLINES.HYPERLINK]: (node, children) => {
          return (
            <a className="link" href={node.data.uri}>
              {node.content[0].value}
            </a>
          )
        },
        "embedded-asset-block": (node, children) => {
          const alt = node.data.target.title
          const url = node.data.target.file.url
          const imgId = node.data.target.file.id

          return <div style={{"display":"flex", "flexFlow": "column", "justifyContent": "center", "alignItems": "center"}}>
            <img alt={alt} src={url} style={{"width": "80%", "margin": "1rem auto"}}/>
            <p style={{"fontSize": "0.85rem"}}>Image: {alt}</p>
          </div>
         },
      },
      blockquote: node => {
        return <blockquote className="offer">{node.content}</blockquote>
      },
      hyperlink: node => {
        const altText = node.content[0].value
        const url = node.data.uri
        return (
          <a className="link" href={url}>
            {altText}
          </a>
        )
      },
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo
          title={caseTitle}
          description={excerpt}
          pathname={this.props.location.pathname}
          image={image}
          featuredImage={image}
        />

        <AboveFold isLight>
          <Row>
            <Breadcrumb>
              <li>
                <a href="/case-studies/">All case studies</a>
              </li>
              <li>
                <a href={`${slug}`}>{caseTitle}</a>
              </li>
            </Breadcrumb>
          </Row>
            <Row isCentered>
            <Column50>
              <TextSmall textSmall={`Written on ${post.date} | ${post.description.childMarkdownRemark.timeToRead} min read` } /> 
              <BlogTitle isPrimary title={caseTitle}/>
              <SubHeading subheading={excerpt} />
              <Author
                authorName={`${authorName}`}
                avatarImg={imgAuth}
                authorTitle="Strategist @School of Content"
              />
            </Column50>
            <Column50>
              <ImageHome src={image} alt="homepage" />
            </Column50>
          </Row>
        </AboveFold>

        <Section isCentered>
          <BlogRow isCentered>
            <BlogBody style={{ whiteSpace: "pre" }}>
                {caseContent?.raw && renderRichText(caseContent, options)}  
              </BlogBody>
              <ReturnLink
                isCentered
                anchortext="Return to all case studies"
                href="/case-studies/"
              />
          </BlogRow>
          <Subscribe />
        </Section>
      </Layout>
    )
  }
}

export default CaseStudyTemplate
